import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { StickyContainer } from "react-sticky";
import Header from "../common/components/Header";
import Dashboard from "../views/home";
import PatientDashboard from "../views/patientDashboard";
import ReferralDashboard from "../views/referralDashboard";
import ReferralPatients from "../views/referralPatients";
import ClinicProcedurePage from "../views/clinicProcedure";
import HospitalProcedurePage from "../views/hospitalProcedure";
import UltrasoundProcessingPage from "../views/ultrasoundProcessing";
import NewPatientBinderPage from "../views/newPatientBinder";
import PostClinicPage from "../views/postClinic";
import PriorAuthorizationPage from "../views/priorAuthorization";
import ClinicReminders from "../views/clinicReminders";
import ClinicWorkFlow from "../views/clinicWorkflow";
import Communication from "../views/communication";
import PatientRecords from "../views/patientRecords";
import EmployeeWorkflow from "../views/employeeWorkflow";
import InventoryWorkflow from "../views/inventoryWorkflow";
import ProcedureWorkflow from "../views/procedureWorkflow";
import Administrator from "../views/administrator";
import Billing from "../views/billing";
import LoginPage from "../views/login";
import ResetPassword from "../views/resetPassword";
import QuickRegister from "../views/quickRegister";
import Support from "../views/supportPage";
import AboutPage from "../views/about";
import LocationSchedulePage from "../views/locationSchedule";
import ProcessesPage from "../views/processesPage";
import { useSelector } from "react-redux";
import UploadImageExamPage from "src/views/upload";

const AppRouters = () => {
  const usersCheck = useSelector((state) => state.common.currentUserInfo);

  const hideHeaderAndStickyContainer = window.location.pathname === "/upload-image-exam";

  return (
    <BrowserRouter>
      <StickyContainer>
        {!hideHeaderAndStickyContainer ? <Header /> : null}
        <Switch>
          <Route path="/" exact component={Dashboard} />
          <Route path="/patient-dashboard" exact component={PatientDashboard} />
          <Route path="/referral-dashboard" exact component={ReferralDashboard} />
          <Route path="/referral-patient-records" exact component={ReferralPatients} />
          <Route path="/login" exact component={LoginPage} />
          <Route path="/resetPassword/:query?" component={ResetPassword} />
          <Route path="/mon/:key" exact component={LocationSchedulePage} />
          <Route path="/clinic-procedure" exact component={ClinicProcedurePage} />
          <Route path="/hospital-procedure" exact component={HospitalProcedurePage} />
          <Route path="/ultrasound-procedure" exact component={UltrasoundProcessingPage} />
          <Route path="/new-patient-binder" exact component={NewPatientBinderPage} />
          <Route path="/post-clinic-binder" exact component={PostClinicPage} />
          <Route path="/prior-authorizations" exact component={PriorAuthorizationPage} />
          <Route path="/subsidiary-workflow/:nav" exact component={QuickRegister} />
          <Route path="/clinic-reminders" exact component={ClinicReminders} />
          <Route path="/clinic-workflow/:nav" exact component={ClinicWorkFlow} />
          <Route path="/patient-records/:nav" exact component={PatientRecords} />
          <Route path="/communication/:nav" exact component={Communication} />
          <Route path="/employee-workflow/:nav" exact component={EmployeeWorkflow} />
          <Route path="/inventory-workflow/:nav" exact component={InventoryWorkflow} />
          <Route path="/obl-workflow/:nav" exact component={ProcedureWorkflow} />
          {usersCheck && usersCheck.is_admin === 1 && <Route path="/administrator/:nav" exact component={Administrator} />}
          {(usersCheck && usersCheck.is_admin === 1) || (usersCheck && usersCheck.is_billing === 1) ? (
            <Route path="/billing/:nav" exact component={Billing} />
          ) : null}
          <Route path="/upload-image-exam" component={UploadImageExamPage} />
          <Route path="/support" exact component={Support} />
          <Route path="/support/:nav" exact component={Support} />
          <Route path="/processes/:nav" exact component={ProcessesPage} />
          <Route path="/about" exact component={AboutPage} />
          <Route render={() => <Redirect to={"/"} />} />
        </Switch>
      </StickyContainer>
    </BrowserRouter>
  );
};

export default AppRouters;
